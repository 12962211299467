<template>
  <div
    v-loading="isLoading"
    class="h-full container mx-auto flex justify-center items-center text-black"
  >
    <div
      class="bg-white w-full p-4 rounded-lg shadow flex justify-center items-center flex-col gap-2"
      style="max-width: 600px"
    >
      <svg
        v-if="isPaymentSuccess"
        width="64"
        height="64"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#32D583"
          d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512c282.784 0 512-229.216 512-512C1024 229.232 794.784 0 512 0m0 961.008c-247.024 0-448-201.984-448-449.01c0-247.024 200.976-448 448-448s448 200.977 448 448s-200.976 449.01-448 449.01m204.336-636.352L415.935 626.944l-135.28-135.28c-12.496-12.496-32.752-12.496-45.264 0c-12.496 12.496-12.496 32.752 0 45.248l158.384 158.4c12.496 12.48 32.752 12.48 45.264 0c1.44-1.44 2.673-3.009 3.793-4.64l318.784-320.753c12.48-12.496 12.48-32.752 0-45.263c-12.512-12.496-32.768-12.496-45.28 0"
        />
      </svg>
      <svg
        v-if="isPaymentFailure"
        width="64"
        height="64"
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="#d92d20"
          d="M28 51.906c13.055 0 23.906-10.828 23.906-23.906c0-13.055-10.875-23.906-23.93-23.906C14.899 4.094 4.095 14.945 4.095 28c0 13.078 10.828 23.906 23.906 23.906m0-3.984C16.937 47.922 8.1 39.062 8.1 28c0-11.04 8.813-19.922 19.876-19.922c11.039 0 19.921 8.883 19.945 19.922c.023 11.063-8.883 19.922-19.922 19.922m-8.016-9.984c.516 0 .985-.211 1.336-.586l6.657-6.68l6.656 6.68c.351.351.82.586 1.36.586c1.03 0 1.874-.868 1.874-1.899c0-.539-.21-.984-.562-1.336l-6.657-6.656l6.68-6.703c.375-.399.563-.797.563-1.313a1.865 1.865 0 0 0-1.875-1.875c-.493 0-.915.164-1.313.563l-6.727 6.703l-6.703-6.68c-.351-.375-.773-.539-1.289-.539c-1.054 0-1.875.797-1.875 1.852c0 .515.188.96.563 1.312l6.656 6.68l-6.656 6.68c-.375.328-.563.796-.563 1.312c0 1.031.82 1.898 1.875 1.898"
        />
      </svg>
      <h2 class="text-center fs-24 mt-3">{{ responseLabel }}</h2>

      <div v-html="message"></div>
      <div class="mt-3">
        <!-- <el-button
          v-if="isPaymentFailure"
          @click="paymentWithOnePay"
          type="primary"
          size="medium"
          >{{ $t("Thanh toán lại") }}</el-button
        > -->
        <el-button @click="handleBack" type="primary" plain size="medium">{{
          $t("Quay lại")
        }}</el-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { TRANSACTION_ERROR } from '../../utils/constants'
export default {
  name: 'PaymentResponseMultiples',
  data () {
    return {
      responseLabel: '',
      receiptInfo: {},
      isLoading: false,
      message: ''
    }
  },
  watch: {
    $conectedEcho: {
      handler () {
        // this.onConnectSocket()
      }
    }
  },
  computed: {
    isPaymentSuccess () {
      return this.$route.query.vpc_TxnResponseCode === '0'
    },
    isPaymentFailure () {
      return (
        this.$route.query.vpc_TxnResponseCode &&
        this.$route.query.vpc_TxnResponseCode !== '0'
      )
    }
  },
  async mounted () {
    // this.onConnectSocket()

    const responseCode = this.$route.query.vpc_TxnResponseCode
    this.handleCheckResponsePayment(responseCode)
  },
  methods: {
    async handleCheckResponsePayment (responseCode) {
      if (responseCode && Number(responseCode) !== 0) {
        const messageError =
          TRANSACTION_ERROR.find((item) => item.value === responseCode)
            ?.label || ''

        this.message = `${this.$t(
          messageError
        )} Vui lòng kiểm tra và thanh toán lại.`
        this.responseLabel = 'Thanh toán thất bại'
        this.isLoading = false
      } else if (responseCode && Number(responseCode) === 0) {
        this.message = `Thanh toán phiếu thu thành công.`
        this.responseLabel = 'Thanh toán thành công'
      }
    },
    handleBack () {
      this.$router.replace({
        name: 'PaymentManagement'
      })
    }
  }
}
</script>
  
  <style lang="scss" scoped>
</style>
  